export const navContent = [
    {
        chn: '最近活動',
        jp: '最近の活動',
        link: '#recent_events'
    },
    {
        chn: '公司概要',
        jp: '企業情報',
        link: '#company_info'
    },
    {
        chn: '服務',
        jp: '事業案内',
        link: '#services'
    },
    {
        chn: '活動回顧',
        jp: 'イベントの回顧',
        link: '#event_gallery'
    },
    {
        chn: '聯絡我們',
        jp: 'お問い合わせ',
        link: '#contact_us'
    },
    {
        chn: 'tsn 記事',
        jp: '実績',
        link: 'past_events'
    }
]
