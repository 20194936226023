export const services = [
    {
        chn: '活動規劃',
        jp: 'イベント企画'
    },
    {
        chn: '藝術家經紀',
        jp: '音楽家、アーティストのマネジメント'
    },
    {
        chn: '不動產相關諮詢',
        jp: '不動産取引コンサルティング'
    },
    {
        chn: '日文/中文 翻譯',
        jp: '日本語と台湾華語の通訳、翻訳'
    }
]
