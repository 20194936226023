const img0 =
    '/images/鳥取/LINE_ALBUM_2023日本鳥取県-台湾学校作品合同展_231210_1.webp'
const img1 =
    '/images/鳥取/LINE_ALBUM_2023日本鳥取県-台湾学校作品合同展_231210_2.webp'
const img2 =
    '/images/鳥取/LINE_ALBUM_2023日本鳥取県-台湾学校作品合同展_231210_3.webp'
const img3 =
    '/images/鳥取/LINE_ALBUM_2023日本鳥取県-台湾学校作品合同展_231210_4.webp'
const img4 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_1.webp'
const img5 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_2.webp'
const img6 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_3.webp'
const img7 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_4.webp'
const img8 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_5.webp'
const img9 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_6.webp'
const img10 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_7.webp'
const img11 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_8.webp'
const img12 = '/images/和歌山/LINE_ALBUM_829和歌山_231003_1.webp'
const img13 = '/images/和歌山/LINE_ALBUM_829和歌山_231003_2.webp'
const img14 =
    '/images/和泉市/LINE_ALBUM_2023 和泉市　台湾茶体験講座_231209_1.webp'
const img15 =
    '/images/和泉市/LINE_ALBUM_2023 和泉市　台湾茶体験講座_231209_2.webp'
const img16 =
    '/images/和泉市/LINE_ALBUM_2023 和泉市　台湾茶体験講座_231209_3.webp'
const img17 =
    '/images/和泉市/LINE_ALBUM_2023 和泉市　台湾茶体験講座_231209_4.webp'
const img18 =
    '/images/和泉市/LINE_ALBUM_2023 和泉市　台湾茶体験講座_231209_5.webp'

const simg0 =
    '/images/鳥取/LINE_ALBUM_2023日本鳥取県-台湾学校作品合同展_231210_1_small.webp'
const simg1 =
    '/images/鳥取/LINE_ALBUM_2023日本鳥取県-台湾学校作品合同展_231210_2_small.webp'
const simg2 =
    '/images/鳥取/LINE_ALBUM_2023日本鳥取県-台湾学校作品合同展_231210_3_small.webp'
const simg3 =
    '/images/鳥取/LINE_ALBUM_2023日本鳥取県-台湾学校作品合同展_231210_4_small.webp'
const simg4 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_1_small.webp'
const simg5 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_2_small.webp'
const simg6 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_3_small.webp'
const simg7 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_4_small.webp'
const simg8 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_5_small.webp'
const simg9 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_6_small.webp'
const simg10 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_7_small.webp'
const simg11 =
    '/images/和歌山/LINE_ALBUM_2023和歌山市 第二回日本台灣學校美術作品合同展_231209_8_small.webp'
const simg12 = '/images/和歌山/LINE_ALBUM_829和歌山_231003_1_small.webp'
const simg13 = '/images/和歌山/LINE_ALBUM_829和歌山_231003_2_small.webp'
const simg14 =
    '/images/和泉市/LINE_ALBUM_2023 和泉市　台湾茶体験講座_231209_1_small.webp'
const simg15 =
    '/images/和泉市/LINE_ALBUM_2023 和泉市　台湾茶体験講座_231209_2_small.webp'
const simg16 =
    '/images/和泉市/LINE_ALBUM_2023 和泉市　台湾茶体験講座_231209_3_small.webp'
const simg17 =
    '/images/和泉市/LINE_ALBUM_2023 和泉市　台湾茶体験講座_231209_4_small.webp'
const simg18 =
    '/images/和泉市/LINE_ALBUM_2023 和泉市　台湾茶体験講座_231209_5_small.webp'

export const carouselImages = [
    {
        title: '2023和歌山市第二回日本台灣學校美術作品合同展',
        src: img12,
        srcSm: simg12,
        alt: 'img1'
    },
    {
        title: '2023和歌山市第二回日本台灣學校美術作品合同展',
        src: img13,
        srcSm: simg13,
        alt: 'img2'
    },
    {
        title: '2023鳥取市第二回日本台灣學校美術作品合同展',
        src: img1,
        srcSm: simg1,
        alt: 'img3'
    },
    {
        title: '2023鳥取市第二回日本台灣學校美術作品合同展',
        src: img2,
        srcSm: simg2,
        alt: 'img4'
    },
    {
        title: '2023鳥取市第二回日本台灣學校美術作品合同展',
        src: img3,
        srcSm: simg3,
        alt: 'img5'
    }
]

// event gallery
export interface Event {
    title: string
    description: string
    imgs: string[]
    smImgs: string[]
}

export const events: Event[] = [
    {
        title: '2023 和歌山市第二回日本台灣學校美術作品合同展',
        description: '2023 和歌山市第二回日本台灣學校美術作品合同展',
        imgs: [img4, img5, img6, img7, img8, img9, img10, img11],
        smImgs: [simg4, simg5, simg6, simg7, simg8, simg9, simg10, simg11]
    },
    {
        title: '2023 和泉市台灣茶體驗講座',
        description: '2023 和泉市台灣茶體驗講座',
        imgs: [img14, img15, img16, img17, img18],
        smImgs: [simg14, simg15, simg16, simg17, simg18]
    },
    {
        title: '2023 鳥取市第二回日本台灣學校美術作品合同展',
        description: '2023 鳥取市第二回日本台灣學校美術作品合同展',
        imgs: [img0, img1, img2, img3],
        smImgs: [simg0, simg1, simg2, simg3]
    }
]
